<template>
    <dev class="top-right-widget fixed top-2 right-2">
        <!-- 语言选择下拉菜单 -->
        <div class="top-right-widget">
            <div id="langDropdown" class="dropdown dropdown-hover dropdown-end mr-2">
            <label tabindex="0" class="btn m-1">
                {{ getFlagEmoji(currentLanguage) }}
            </label>
            <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                <li v-for="lang in languages" :key="lang.code">
                    <a @click="changeLanguage(lang.code)">
                        {{ lang.name }}
                        <span class="ml-2 text-lg">{{ lang.emoji }}</span>
                    </a>
                </li>
                </ul>
            </div>
        </div>
        
        
        <div class="top-right-widget" v-if="user.isLogin">
            <div id="dropdownMenu" class="dropdown dropdown-hover dropdown-end">
                <div tabindex="0" role="button" class="btn m-1" id="paymenubtn">
                    <CoinInfo />
                </div>
                <div tabindex="0" id="paymenu" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
                        <div class="payment-card bg-base-100 p-4" v-if="user.isLogin && !loading">
                            <div class="card dark:bg-base-300 border border-gray-300 dark:border-gray-900 dark:text-gray-100 hover:cursor-pointer hover:scale-105 hover:shadow-lg transition-all duration-300" 
                                 v-for="option in paymentOptions" 
                                 :key="option.id"
                                 @click="triggerPurchase($event, option.id)">
                                <div class="card-header">
                                    <h2 class="text-2xl text-center">{{ option.title }}</h2> <!-- 较大的标题 -->
                                </div>
                                <div class="card-body">
                                    <!-- <p class="text-base text-center text-gray-400">{{ option.description }}</p> 默认的文本大小 -->
                                    <h1 class="text-3xl text-center" v-html="option.price"></h1> <!-- 特别突出的价格 -->
                                    <p class="text-sm text-center text-gray-700 dark:text-gray-300" style="margin-top:0px">包括{{
                                        option.coins }}🥇</p>
                                    <!-- 较小的详细信息 -->
                                    <button class="btn-buy btn btn-accent hover:bg-accent-focus hover:scale-105 transition-all duration-300">
                                        立即购买
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center">
                            <span class="loading loading-ring loading-lg"></span>
                        </div>
                        <SuperPayment id="buyButton" :productID="productID" v-show="false" />
                    <ul>
                        <li class="menu">
                        <LoginPage />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <LoginPage v-else />
        <!-- <RedeemCode /> -->
    </dev>
    <div v-if="showWechatGuide" class="fixed inset-0 bg-black bg-opacity-75 justify-center items-center z-50"
        @click="showWechatGuide=false">
        <img src="../assets/wechatguide.png" alt="Open in browser instructions" class="max-w-full h-auto">
    </div>
</template>

<script setup>
import { reactive, inject, watch, ref, nextTick, computed } from 'vue';
import { useRoute } from 'vue-router';
import CoinInfo from '../components/CoinInfo.vue';
import SuperPayment from '../components/SuperPayment.vue';
import LoginPage from './User/LoginPage.vue';
//   import RedeemCode from '@/components/RedeemCode.vue';
import { getAnalytics, logEvent } from "firebase/analytics";

let user = inject('user');

const analytics = getAnalytics();

// 点击外部关闭菜单
const closeDropdown = event => {
    const dropdownMenu = document.getElementById('dropdownMenu');
    if (!dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.remove('dropdown-open');
    }
};

watch(user, (newUser) => {
    if (newUser.showChargeCoin) {
        user.showChargeCoin = false;
        // 展示充值面板 paymenubtn激活
        var dropdown = document.getElementById('dropdownMenu');
        dropdown.classList.toggle('dropdown-open');
        document.addEventListener('click', closeDropdown);
    }
});

const paymentOptions = reactive([
{ id: "coin_20", title: '尝鲜包', price: '$2.9', coins: '220', description: '体验一下' },
{ id: "coin_100", title: '超值包', price: '$14.9&nbsp<del class="text-gray-400 text-2xl">$20</del>', coins: '1600', description: '最优惠充值' },
{ id: "coin_50", title: '标准包', price: '$7.9&nbsp<del class="text-gray-400 text-2xl">$10</del>', coins: '600', description: '50元优惠充值' },
]);

const productID = ref('');
const loading = ref(false);
const showWechatGuide = ref(false);

const route = useRoute();

const languages = [
    { code: 'zh', name: '中文', emoji: '🇨🇳' },
    { code: 'en', name: 'English', emoji: '🇺🇸' },
    { code: 'ja', name: '日本語', emoji: '🇯🇵' },
];

const currentLanguage = computed(() => {
    return route.path.split('/')[1] || 'zh';
});

// 

const changeLanguage = (langCode) => {
    const currentPath = window.location.pathname;
    let newPath;

    if (langCode === 'zh') {
        // 中文时不需要子路径
        newPath = currentPath.replace(/^\/(en|ja)/, '');
    } else {
        // 英文和日文时增加子路径
        newPath = '/' + langCode + currentPath.replace(/^\/(en|ja)/, '');
    }

    // 直接修改浏览器 URL
    window.history.pushState({}, '', newPath);

    // 刷新页面
    location.reload();
};

const getFlagEmoji = (langCode) => {
    const lang = languages.find(l => l.code === langCode);
    return lang ? lang.emoji : '🇨🇳';
};

const triggerPurchase = (event, optionId) => {
    console.log('购买选项', optionId);
    // 阻止事件冒泡
    event.stopPropagation();

    logEvent(analytics, 'purchase_option_clicked', { optionId });

    // 获取当前网页UA判断是否在微信中，如果在微信中，显示全屏引导，提示用系统浏览器打开
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf("micromessenger") != -1
    if (isWeixin) {
        showWechatGuide.value = true;
        logEvent(analytics, 'wechat_guide_shown');
        return
    }
    let buyButton = document.getElementById('buyButton');
    productID.value = optionId;
    nextTick(() => {
        buyButton.click();
    });
    // 显示loading
    loading.value = true;
    // buyButton.setAttribute('productID', optionId);
    // // id选择
    // buyButton.click();
}
</script>

<style scoped>
.top-right-widget {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
}

#paymenu {
    max-width: 100vw;
}

.payment-card {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 10px;
    white-space: nowrap;
    max-width: 90vw;
}

@media (max-width: 544px) {

    /* 对应 Tailwind CSS 的 md 断点 */
    #paymenu {
        transform: scale(0.6);
        transform-origin: 25% 0%;
    }
    .payment-card {
        max-width: 150vw;
    }
}

.card {
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    padding-top: 20px;
}

.card-header {
    font-size: 18px;
    font-weight: bold;
}

.card-body p {
    font-size: 14px;
    margin: 5px 0;
}

.card-body {
    padding-top: 20px;
}

.btn-buy {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.dropdown .btn {
    height: 2.5rem; /* 调整高度以匹配其他按钮 */
    min-height: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dropdown-content {
    min-width: 120px;
}

.dropdown-content li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 可能需要调整 emoji 的大小 */
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
</style>